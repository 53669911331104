// Colors
$link-color: #007bff;
$link-decoration: none;
$link-hover-decoration: underline;
$link-hover-color: #0056b3;
//
// Links
//
a {
    color: $link-color;
    text-decoration: $link-decoration;
    background-color: transparent; // Remove the gray background on active links in IE 10.

    &:hover() {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
    }
  }

//
// Buttons
//
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
border-color: transparent;
}

//
// Containers
//
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

}

//
// Tab
//
.mat-mdc-tab-group {
    gap: 1px;
    .mat-ripple.mat-mdc-tab-header-pagination:not([disabled]) {
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
}


//
// Row
//
.row {
    margin-left: -15px;
    margin-right: -15px;
}
.row>* {
    padding-left: 15px;
    padding-right: 15px;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row>* {
    padding-left: 5px;
    padding-right: 5px;
}

//
// small
//
.small, small {
    font-size: 80%;
    font-weight: 400;
}


//
// badge
//
.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
}


//
// Modal
//
.mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface {
    box-shadow: none;
    border-radius: 0;
}
.modal-header{
    .close {
        border: 0;
        background-color: transparent;
        padding: 1rem 1rem;
        margin: -1rem -1rem -1rem auto;
    }
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}

.modal-footer>* {
    margin: .25rem;
}


hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
    opacity: 1;
}

.card-body {
    padding: 1.25rem;
}

.mat-mdc-slide-toggle {
    --mdc-switch-unselected-handle-color: #fff;
    --mdc-switch-unselected-hover-handle-color: #fff;
    --mdc-switch-unselected-focus-handle-color: #fff;
    --mdc-switch-unselected-pressed-handle-color: #fff;
    --mdc-switch-disabled-unselected-handle-color: #fff;

    &.mat-accent {
        --mdc-switch-selected-handle-color: #ff7700;
        --mdc-switch-selected-hover-handle-color: #ff7700;
        --mdc-switch-selected-focus-handle-color: #ff7700;
        --mdc-switch-selected-pressed-handle-color: #ff7700;
        --mdc-switch-disabled-selected-handle-color: #ff7700;
    }

    .mdc-switch--unselected {
        .mdc-switch__shadow {
            background-color: #fff;
        }

        .mdc-switch__track::before {
            background-color: rgba(0, 0, 0, 0.38) !important;
        }
    }

    &.mat-mdc-slide-toggle-checked:not(.mat-disabled) {
        .mdc-switch__shadow {
            background-color: #ff7700;
        }
        .mdc-switch__track::after {
            background-color: #f0ab6f !important;
        }
    }

    .mdc-form-field {
        vertical-align: initial;
        &>label {
            font-family: Roboto, "Helvetica Neue", sans-serif;
            padding-left: 8px;
        }
    }
}

