/* For use in src/lib/core/theming/_palette.scss
500 primary
colors #ff7700 #f0ab6f
*/
$md-digopalette: (
    disabled-text: #000000,
    50 : #ffefe0,
    100 : #ffd7b3,
    200 : #f0ab6f,
    300 : #f0ab6f,
    400 : #ff8d26,
    500 : #ff7700,
    600 : #ff7700,
    700 : #ff6600,
    800 : #ff5c00,
    900 : #ff7700,
    A100 : #ffffff,
    A200 : #fff5f2,
    A400 : #ffcebf,
    A700 : #ffbaa6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

