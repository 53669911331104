wc-ocs-theme {
    &.modal-kyc {
        .orange-cms {
            .form-group {
                margin-bottom: 1.5rem;

                .custom-select, .form-select {
                    position: relative;
                    padding: 0;
                    margin: 1rem 0;
                    border: none;
                    background-color: initial;
                    background-image: none;
                    background-repeat: initial;
                    background-attachment: initial;
                    background-position: initial;
                    font-weight: normal;
                    font-family: "HelvNeueOrange";
                    font-size: 1.2rem;
                }
                .checkbox-radio {
                    .radio {
                        input {
                            display: none;
                        }
                        label {
                            &::before {
                                top: 0;
                                bottom: 0;
                                margin: auto;
                            }
                            &::after {
                                top: 0;
                                bottom: 0;
                                margin: auto;
                            }
                        }
                    }
                }

                .control-label {
                    font-size: 1.4rem;
                    line-height: 1.4rem;
                    color: #606060;

                    position: relative;
                    padding-left: 0;
                    margin-bottom: 1rem;
                }
            }
            .list-features {
                li {
                    font-size: 1rem;
                    line-height: 1.2rem;
                }
            }

            .btn-md {
                font-size: 1.2rem;
            }
            .modal-body {
                p {
                    font-size: 1.4rem;
                }
            }
            .boxinfo-info {
                font-size: 1.2rem;
                li {
                    font-size: 1rem;
                    line-height: 1.6rem;
                }
            }
            .step-form-counter {
                .step-container {
                    flex-wrap: nowrap;
                    .step-title {
                        font-size: 1rem;
                        line-height: 1.4rem;
                    }
                }
                .step {
                    margin-left: 0;
                    width: unset;
                }
            }
        }
        wc-ocs-doiupload,  wc-ocs-doiposupload{
            & > ocs-button {
                display: none;
            }
        }
    }
}

#html-digo {
    font-size: 100%;
}
#html-digo body {
    font-size: 1rem;
    color: #212529;
}

.mat-mdc-form-field {
    .mat-datepicker-input {
        font: inherit;
        height: inherit;
    }
    .mat-mdc-form-field-text-suffix {
        .mat-datepicker-toggle {
            position: relative;
        }
    }

    .mat-datepicker-toggle-default-icon {
        fill: currentColor !important;
    }
}

@media only screen and (min-width: 768px) {
    wc-ocs-theme {
        &.modal-kyc {
            .orange-cms {
                .font-sm {
                    font-size: 1.4rem;
                    line-height: 1.6rem;
                    letter-spacing: -0.1px;
                }
                .title-page {
                    .font-sm {
                        font-size: 1.8rem;
                        line-height: 2rem;
                        letter-spacing: -0.2px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    wc-ocs-theme {
        &.modal-kyc {
            .orange-cms {
                .step-form-counter {
                    .step-container {
                        .step.current {
                            .step-title {
                                font-size: 1rem;
                                line-height: 1.4rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
